import React, { Component } from 'react';
import '../SubjectIcon/SubjectIcon.css'

export class TypeIcon extends Component {
    constructor(props) {
        super(props);  
    }

    getFirst(id, input) {
        if (!input && !id){
            return "";
        }
        //console.log('podaci', id, input);
        if (id == '3') return '1';
        else if (id == '4') return '2';
        else if (id == '5') return '3';
        else if (id == '6') return '4';
        else if (id == '10') return 'P';

        return input.substring(0, 1).toUpperCase();
    }
    render() {
        return (
            <div className={"icon-container"}>
               <div className="icon-style">{this.getFirst(this.props.id, this.props.naziv)}</div>
            </div>
        ); 
    }
}