import {userConstants} from '../_constants/user.constants';

export function success(user) { 
	return { type: userConstants.LOGIN_SUCCESS, user };
}

export function failure() { 
	return { type: userConstants.LOGIN_FAILURE} ;
}

export function logout() { 
	return { type: userConstants.LOGOUT} ;
}

export function setClasses(class_data) { 
	let class_id = '';
	let class_name = '';
	if (class_data && class_data.length > 0) {
		class_id = class_data[0].id;	
		class_name = class_data[0].naziv;	
	}
	return { type: userConstants.SET_CLASSES, class_id : class_id, class_name : class_name, 'class_data' : class_data};
}

export function setCurrClass(class_id, class_data) { 
	let class_name = '';
	for (let i = 0; i < class_data.length; i++) {
		if (class_id == class_data[i].id){
			class_name = class_data[i].naziv;
			break;
		}
	}
	return { type: userConstants.SET_CLASSES, class_id : class_id, class_name : class_name, 'class_data' : class_data};
}

export function clearClasses() { 
	return { type: userConstants.CLEAR_CLASSES};
}