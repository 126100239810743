import React, { Component } from 'react';
import '../Main.css'
import { connect } from 'react-redux';
import axios from 'axios';
//import {history} from '../_helpers/history';
import { store } from '../../_helpers/store';
import {Spiner} from '../../Spiner/Spiner';
import Moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import AttIcon from './AttIcon';
import AttLineChart from './AttLineChart';
import AttSumChart from './AttSumChart';

class Attendance extends React.PureComponent {

	constructor(props) {
        super(props);  

        this.handleOnRowSelected = this.handleOnRowSelected.bind(this);
    }

	componentDidMount() {		
	}

	handleOnRowSelected(e, mark, index){
		this.props.onRowSelected(mark);
	}

	getMjesec = (datum) => {
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (datum) => {
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getAvg(sum, cnt){
		if (cnt == 0)
			return '';

		return Math.round((sum / cnt) * 100) / 100;
	}
	getTekucaStatistika = (attendance) =>{
		let data = [];
		
		if (attendance.length > 0){
			let item = attendance[ attendance.length - 1 ];
			data.push({name : 'neopravdani', value : item.broj_neopravdanih});
			data.push({name : 'opravdani', value : item.broj_opravdanih});
		}
		return data;
	}

	getUkupnaStatistika = (attendance) =>{
		let data = [];

		attendance.map((item, index) =>	{
			let new_item = {};
			new_item.datum = Moment(item.datum_azuriranja).format('DD.MMM');
			new_item.opravdani = item.broj_opravdanih;
			new_item.neopravdani = item.broj_neopravdanih;
			data.push(new_item);
		});
		return data;
	}


    render() {
    	const attendance = this.props.attendance;
  		
  		return (
  			
        	<div>        		
				<Grid container spacing={3}>
					<Grid item md={8} className="marks-grid-container">
			            <div className="marks-container">
				            <List style={{padding:'0px', margin : '0px'}}>			
								{attendance.map((item, index) =>					
									<ListItem button key={index} style={{padding:'0px', margin : '0px'}}>
										<div className="main-flex-container">
											<AttIcon naziv={item.naziv_perioda}/>
											<div className="right-flex-container">
												<div className="upper-flex-container">
													<div className="main-text">
														{item.naziv_perioda}
													</div>										
												</div>
												<div className="bottom-flex-container w-100">
													<Grid container spacing={1}>
        												<Grid item sm={4}>	
        													{'Broj opravdanih ' + item.broj_opravdanih}												
        												</Grid>
        												<Grid item sm={4}>								
        													{'Broj neopravdanih ' + item.broj_neopravdanih}		
        												</Grid>
        												<Grid item sm={4}>								
        													{'Broj na čekanju ' + item.broj_na_cekanju}				
        												</Grid>
        											</Grid>
													
												</div>
											</div>
											<div className="date-container">
												<div>{this.getDan(item.datum_azuriranja)}</div>
												<div>{this.getMjesec(item.datum_azuriranja)}</div>
											</div>
										</div>
									</ListItem>
								)}
							</List>
						</div>
					</Grid>
					<Grid item md={4} className="chart-grid-container">
						<div className="chart-container">
				          	<AttSumChart data={this.getTekucaStatistika(this.props.attendance)} />
				        </div>
						<div className="chart-container chart-container-mt3">
				          	<AttLineChart data={this.getUkupnaStatistika(this.props.attendance)}/>
				        </div>
			    	</Grid>
			     </Grid>
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const { class_id } = state.school_classes;
    return {
        loggedIn, user, class_id
    };
}

const connectedAttendance  = connect(mapStateToProps)(Attendance);
export {connectedAttendance as Attendance}; 