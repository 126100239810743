import { createStore, applyMiddleware, combineReducers } from 'redux';
//import thunkMiddleware from 'redux-thunk';
//import { createLogger } from 'redux-logger';
//import rootReducer from '../_reducers/*';
import {authentication} from '../_reducers/authentication.reducer'
import {school_classes} from '../_reducers/school_class.reducer'
//const loggerMiddleware = createLogger();
/*,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )*/
    
export const store = createStore(
    combineReducers({authentication : authentication, school_classes : school_classes})
);
