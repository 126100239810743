import React, { Component } from 'react';
import Moment from 'moment';
import {Spiner} from '../Spiner/Spiner';
import Grid from '@material-ui/core/Grid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export class AddNotif extends React.PureComponent {
	constructor(props) {
        super(props);  
        this.state = {text : '', naslov : ''} ;
    }
    //
	componentDidMount() {	
		if (this.props.data.id)
        	this.setState({text : this.props.data.body, naslov : this.props.data.headline, id_razreda : this.props.data.id_razreda})	;	
	}

	onChange = (text) => {
	    this.setState({text});
	};
	
	handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

	modules = {
	    toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline','strike', 'blockquote'],
			[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
			['link', 'image'],
			['clean'], 
			[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
	    ],
	}

	handleButtonClick = () => {
		if (this.props.onSave)
			this.props.onSave({id : this.props.data.id, headline : this.state.naslov, html : this.state.text, id_razreda : this.state.id_razreda});
	}

    render() {
    	return (
  			
        	<div className="date-container">
        		
        			<TextField  margin="normal" fullWidth id="username" label="Naslov objave" name="naslov" 
		            	autoFocus
                        placeholder="Unesite naslov objave"
                        onChange={this.handleChange}
                        value={this.state.naslov}
                    />
                    <FormControl ariant="outlined" style={{width : '100%', marginBottom : 20, marginTop : 5}}>
						<InputLabel htmlFor="id_razreda" style={{fontWeight : 'bold'}}>Odaberite razred</InputLabel>
						<Select value={this.state.id_razreda} onChange={this.handleChange} input={<Input name="id_razreda" id="id_razreda" />}>
							{this.props.razredi.map((type, index) =>		
								<MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
							)}
						</Select>
					</FormControl>
		            <ReactQuill
		            	defaultValue={this.props.data.id?this.props.data.body:''}
				        value={this.state.text || ''}
				        onChange={this.onChange}
				        modules={this.modules}
				        style={{width : '100%', marginBottom : 10}}
				      />
					<Button type="submit" variant="contained" color="primary"
						href="javascript:void(0)"
						onClick={event => this.handleButtonClick()}>
	                    Sačuvaj
	                </Button>
			</div>
        );
    }
}
