import React, { Component } from 'react';
import {Spiner} from '../../Spiner/Spiner';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export class ParentCoop extends React.PureComponent {

	constructor(props) {
        super(props);  
    }

	componentDidMount() {		
	}

    render() {    	
    	const text = this.props.parent_coop.saradnja_sa_roditeljima;
  		
  		/*if (text)
  			console.log(text.split(/\r?\n/));
  		*/
  		return (
  			
        	<div>        		
				<Grid container spacing={3}>
					<Grid item md={12} className="marks-grid-container">
			            <div className="marks-container">
				            <List style={{padding:'0px', margin : '0px'}}>			
												
									<ListItem button style={{padding:'0px', margin : '0px'}}>
										<div className="main-flex-container">											
											<div className="right-flex-container">
												<div className="bottom-flex-container w-100"  style={{marginTop : 5}}>
													<div className="prewrap">
													{text}
													</div>
												</div>
											</div>
										</div>
									</ListItem>
								
							</List>
						</div>
					</Grid>
			    </Grid>
			</div>
        );
    }
}