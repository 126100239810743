import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './_helpers/store';
import axios from 'axios';
import httpService from './network-service';
import {history} from './_helpers/history';
//import 'bootstrap/dist/css/bootstrap.css';
//import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import { BrowserRouter as Router, withRouter} from 'react-router-dom';
import { MuiThemeProvider } from "@material-ui/core/styles"
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';
import cyan800 from '@material-ui/core/colors/cyan';
/*axios.interceptors.request.use(
	function(config) {
		const token = '';
		console.log('interceptors');

		if ( token != null ) {
			config.headers.Authorization = 'Bearer ${token}';
		}

		return config;
	}, 
	function(err) {
  		return Promise.reject(err);
	}
);*/
const theme = createMuiTheme({
  palette: {
    //primary: teal,
    primary: {
      light: '#008896',
      main: '#008896',
      dark: '#008896',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
      //main : '#008896'
    },
  },
});

httpService.setupInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
	  <MuiThemeProvider theme={theme}>
	    	<Router>    	 
	    	 	<App history={history} />    	 
	    	 </Router>
	   </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

//dom.watch();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
