import React, { Component } from 'react';
import Moment from 'moment';

export default class AttIcon extends Component {
	constructor(props) {
        super(props);  
    }

    getFirst(id, input) {
        if (!id){
            return "";
        }
        if (id.startsWith("IV")) return '4';
        else if (id.startsWith("III")) return '3';
        else if (id.startsWith("II")) return '2';
        else if (id.startsWith("I")) return '1';

        return "";
    }

    render() {            
       	return (
       		<div className="icon-container">
       		    <div className="icon-style">{this.getFirst(this.props.naziv)}</div> 
        	</div>

        ); 
    }
}