import React, { Component } from 'react';
import './MainHeader.css'
import { connect } from 'react-redux';
import {logout, setClasses} from '../../_actions/actions';
import {userConstants} from '../../_constants/user.constants';
import {withRouter} from 'react-router-dom'

class MainHeader extends React.Component {
	constructor(props) {
        super(props);

        //this.handleChange = this.handleChange.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }
    handleLogout(e) {
    	const { dispatch } = this.props;
        e.preventDefault();
        dispatch(logout());
		dispatch(setClasses());
		localStorage.setItem(userConstants.MEIS_EDU_TOKEN, null);		
		this.props.history.push("/login");
    }

    render() {
    	const { user } = this.props;

        return (
        <div>
           <div className="d-none d-sm-block bigBar">
				<div className="d-flex justify-content-between align-items-center w-100 h-100">
					<div className="d-flex justify-content-start align-items-center w-100 h-100">					
						<i className="fa fa-graduation-cap fa-2x mx-2" aria-hidden="true"></i>		
						<h6>{user}</h6>				
					</div>

					<div className="d-flex justify-content-end align-items-center w-100 h-100">			
						<a className="navlink px-1" href="javascript:void(0)" onClick={this.handleLogout}>
							<i className="fa fa-sign-out fa-fw fa-2x" aria-hidden="true"></i>						
						</a> 

						<a className="navlink pr-5"
							href="javascript:void(0)" onClick={this.handleLogout}> Odjava 
						</a>				
					</div>
				</div>
			</div>			
			<div className="d-sm-none smallBar">
				<div className="d-flex justify-content-between align-items-center w-100 h-100">
					<div className="d-flex justify-content-start align-items-center w-100 h-100">					
						<i className="fa fa-graduation-cap mx-2" aria-hidden="true"></i>
						<h6>{user}</h6>		
					</div>

					<div className="d-flex justify-content-end align-items-center w-100 h-100">			
						<a className="navlink px-1" href="javascript:void(0)" onClick={this.handleLogout}>
							<i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>						
						</a> 

						<a className="navlink pr-5"
							href="javascript:void(0)" onClick={this.handleLogout}> Odjava 
						</a>				
					</div>
				</div>
			</div>
		</div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const { class_id } = state.school_classes;
    return {
        loggedIn, user, class_id
    };
}

const connectedMainHeader = withRouter(connect(mapStateToProps)(MainHeader));
export {connectedMainHeader as MainHeader}; 