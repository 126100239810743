import React, { Component } from 'react';
import './Conduct.css'
import { connect } from 'react-redux';
import axios from 'axios';
//import {history} from '../_helpers/history';
import { store } from '../../_helpers/store';
import {Spiner} from '../../Spiner/Spiner';
import Moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import ConductIcon from './ConductIcon'

class Conduct extends React.PureComponent {

	constructor(props) {
        super(props);  
    }

	componentDidMount() {		
	}

	getMjesec = (datum) => {
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (datum) => {
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getAvg(sum, cnt){
		if (cnt == 0)
			return '';

		return Math.round((sum / cnt) * 100) / 100;
	}

	getBg(nazivTipa){
		if (nazivTipa.startsWith("Nez"))
            return "red-bg";
        else if (nazivTipa.startsWith("Dob"))
            return "orange-bg";
        else 
            return "green-bg";
	}

    render() {
    	const conduct = this.props.conduct;
  		//<AttIcon naziv={item.naziv_tipa}/>
  		return (
  			
        	<div>        		
				<Grid container spacing={3}>
					<Grid item md={12} className="marks-grid-container">
			            <div className="marks-container">
				            <List style={{padding:'0px', margin : '0px'}}>			
								{conduct.map((item, index) =>					
									<ListItem button key={index} style={{padding:'0px', margin : '0px'}}>
										<div className="main-flex-container">
											<ConductIcon nazivtipa={item.nazivtipa}/>
											<div className="right-flex-container">
												<div className="upper-flex-container">
													<div className="main-text">
														{item.nazivtipa}
													</div>										
												</div>
												<div className="bottom-flex-container w-100">
													<div className={"ocjena-vladanje " + this.getBg(item.naziv)} style={{marginTop : 3}}>
													{item.naziv}
													</div>
												</div>
												<div className="bottom-flex-container w-100"  style={{marginTop : 5}}>
													<div>
													{item.napomena?item.napomena:''}
													</div>
												</div>
											</div>
											<div className="date-container">
												<div>{this.getDan(item.datum)}</div>
												<div>{this.getMjesec(item.datum)}</div>
											</div>
										</div>
									</ListItem>
								)}
							</List>
						</div>
					</Grid>
			     </Grid>
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const { class_id } = state.school_classes;
    return {
        loggedIn, user, class_id
    };
}

const connectedConduct  = connect(mapStateToProps)(Conduct);
export {connectedConduct as Conduct}; 