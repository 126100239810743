import React, { Component } from 'react';
import clsx from 'clsx';
import './Login.css'
import { BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {history} from '../_helpers/history';
import {userConstants} from '../_constants/user.constants';
import {success, failure, setClasses} from '../_actions/actions';
import {Spiner} from '../Spiner/Spiner';
import {LoginHeader} from './LoginHeader/LoginHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import { withTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Android from '@material-ui/icons/Android';
import splash_1 from './splash/splash_1.jpeg'; import splash_2 from './splash/splash_2.jpeg'; import splash_3 from './splash/splash_3.jpeg';
import splash_4 from './splash/splash_4.jpeg'; import splash_5 from './splash/splash_5.jpeg'; import splash_6 from './splash/splash_6.jpeg';
import splash_7 from './splash/splash_7.jpeg'; import splash_8 from './splash/splash_8.jpeg'; import splash_9 from './splash/splash_9.jpeg';
import splash_10 from './splash/splash_10.jpeg'; import splash_11 from './splash/splash_11.jpeg'; import splash_12 from './splash/splash_12.jpeg';
import splash_13 from './splash/splash_13.jpeg'; import splash_14 from './splash/splash_14.jpeg'; import splash_15 from './splash/splash_15.jpeg';
import splash_16 from './splash/splash_16.jpeg'; import splash_17 from './splash/splash_17.jpeg'; import splash_18 from './splash/splash_18.jpeg';
import splash_19 from './splash/splash_19.jpeg'; import splash_20 from './splash/splash_20.jpeg'; import splash_21 from './splash/splash_21.jpeg';
import splash_22 from './splash/splash_22.jpeg'; import splash_23 from './splash/splash_23.jpeg'; import splash_24 from './splash/splash_24.jpeg';
import splash_25 from './splash/splash_25.jpeg'; import splash_26 from './splash/splash_26.jpeg'; import splash_27 from './splash/splash_27.jpg'; 
import splash_28 from './splash/splash_28.jpg'; import splash_29 from './splash/splash_29.jpg'; import splash_30 from './splash/splash_30.jpg'; 
import ReCAPTCHA from "react-google-recaptcha";
import Hidden from '@material-ui/core/Hidden';
import LogoImg from '../Main/img/T-logo-2.png';
import MemoImg from '../Main/img/memo.png';
import LogoImgSmall from '../Main/img/T-logo-3.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
//let splash = null;
import google_play from './splash/google_play.png'; 
import app_store from './splash/app_store.svg'; 

const styles = theme => {   
    return {
        root: {
            [theme.breakpoints.up('md')]: {
                minHeight: 'calc(100vh - 100px)',
            },
            [theme.breakpoints.down('md')]: {
                minHeight: 'calc(100vh - 50px)',
            },
            width : '100%', 
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent : 'center'
        },
        image: {
            //backgroundImage: 'url(https://source.unsplash.com/random)',
            //backgroundImage: `url(${splash})`, 
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1), 
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            height : 50
        },
        margin: {
            margin: theme.spacing(1),
        },
        textField: {
            flexBasis: 200,
        },
        w100: {
            width: '100%', 
        },
        menuButton: {
            marginRight: theme.spacing(2),
          },
    };
};

class Login extends React.Component {
	constructor(props) {
        super(props);    

        this.state = {
            username: '',
            password: '',
            badLoginMsg: '', 
            badLogin: false, 
            loading : false,
            showPassword : false, 
            recaptcha : '', 
            splash : null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    recaptchaRef = React.createRef();

    chooseSpash = () => {
        let splash = null;

        let milicsec = new Date().getTime();
        let splash_cnt = milicsec % 30;
        //console.log('splash_cnt', splash_cnt);
        if (splash_cnt == 0)
            splash = splash_1;
        else if (splash_cnt == 1)
            splash = splash_2;
        else if (splash_cnt == 2)
            splash = splash_3;
        else if (splash_cnt == 3)
            splash = splash_4;
        else if (splash_cnt == 4)
            splash = splash_5;
        else if (splash_cnt == 5)
            splash = splash_6;
        else if (splash_cnt == 6)
            splash = splash_7;
        else if (splash_cnt == 7)
            splash = splash_8;
        else if (splash_cnt == 8)
            splash = splash_9;
        else if (splash_cnt == 9)
            splash = splash_10;
        else if (splash_cnt == 10)
            splash = splash_11;
        else if (splash_cnt == 11)
            splash = splash_12;
        else if (splash_cnt == 12)
            splash = splash_13;
        else if (splash_cnt == 13)
            splash = splash_14;
        else if (splash_cnt == 15)
            splash = splash_16;
        else if (splash_cnt == 16)
            splash = splash_17;
        else if (splash_cnt == 17)
            splash = splash_18;
        else if (splash_cnt == 18)
            splash = splash_19;
        else if (splash_cnt == 19)
            splash = splash_20;
        else if (splash_cnt == 20)
            splash = splash_21;
        else if (splash_cnt == 21)
            splash = splash_22;
        else if (splash_cnt == 22)
            splash = splash_23;
        else if (splash_cnt == 23)
            splash = splash_24;
        else if (splash_cnt == 24)
            splash = splash_25;
        else if (splash_cnt == 25)
            splash = splash_26;
        else if (splash_cnt == 26)
            splash = splash_27;
        else if (splash_cnt == 27)
            splash = splash_28;
        else if (splash_cnt == 28)
            splash = splash_29;
        else if (splash_cnt == 29)
            splash = splash_30;

        //console.log('splash', splash);
        this.setState({splash : splash});
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        //console.log('this.recaptchaRef', this.recaptchaRef, this.recaptchaRef.current);
        e.preventDefault();

        const { username, password, recaptcha } = this.state;
        const { dispatch } = this.props;
        
        this.setState({ loading: true });
        let user  = {'username' : username, 'pass' : password, 'valid' : '2h', recaptcha : recaptcha};

        axios.post('/api/login_captcha', user)
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});
            //console.log('server_data', server_data);
            if (server_data.msg == ''){                
                //store token 
                localStorage.setItem(userConstants.MEIS_EDU_TOKEN, server_data.idToken);
                this.setState({ badLogin : false, badLoginMsg : ''});
                dispatch(success(username));
                dispatch(setClasses(server_data.classes));

                if (server_data.is_admin)
                    this.props.history.push("/admin");
                else
                    this.props.history.push("/main");
            }
            else {
            	this.setState({ badLogin : true, badLoginMsg : server_data.msg, recaptcha : ''});
                if (this.recaptchaRef.current) this.recaptchaRef.current.reset();
                dispatch(failure());
            }
        }
        ,error => {
        	this.setState({ loading: false, badLogin : true, badLoginMsg : 'Došlo je do greške prilikom prijavljivanja', recaptcha : ''});
            if (this.recaptchaRef.current) this.recaptchaRef.current.reset();
            dispatch(failure());
        });
    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword });
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    }

	componentDidMount() {
        this.chooseSpash();
	}

    captchaChange = (value) => {
        this.setState({recaptcha : value});
    }

    render() {
    	const { user } = this.props;
    	const { username, password, submitted, badLoginMsg, badLogin, loading } = this.state;
        const { container, classes } = this.props;

        //<Grid item xs={false} sm={4} md={7} className={classes.image} style={{backgroundImage: `url(${this.state.splash})`}}/>
        //<Typography variant="h6" className={classes.title}>{"Preuzmite"}</Typography>
        /*
        <AppBar position="fixed" className={classes.appBar}>
                 <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                                  {"DNEVNIK"}
                        </Typography>
                  </Toolbar>
                </AppBar>
       */
        return (
            <div>
                
                <Grid container component="main" className={classes.root} style={{backgroundImage: `url(${this.state.splash})`}}>
                    <CssBaseline />
    				{loading &&
    					<Spiner></Spiner>
    				}
                    
                    
                   <Paper elevation={3} style={{maxWidth : 420}}>
                        <Typography component="h6" style={{marginTop: 20, fontStyle: 'italic'}}>
                            Ministarstvo prosvjete Crne Gore
                        </Typography>                    
                        <img src={MemoImg}/>
                        <Typography component="h1" variant="h5">
                            DNEVNIK
                        </Typography>
                        <div style={{margin : '20px', maxWidth : 340, marginLeft : 60 , marginRight : 60}}>
                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                {badLogin &&
                                <div style={{backgroundColor : 'red', color : 'white', borderRadius : 5}}>{badLoginMsg}</div>
                                }
                                <TextField variant="outlined" margin="normal" fullWidth id="username" label="Korisničko ime" name="username" autoFocus
                                    placeholder="Unesite korisničko ime"
                                    onChange={this.handleChange}
                                />
                                <TextField variant="outlined" margin="normal" fullWidth name="password" label="Šifra"
                                    className={classes.textField}
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}                             
                                    onChange={this.handleChange}
                                    value={this.state.password}
                                    placeholder="Unesite šifru"   
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            edge="end"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            onMouseDown={this.handleMouseDownPassword}
                                          >
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                />

                                <div className="centered-container-row">
                                    <ReCAPTCHA
                                        sitekey="6Lfm_bIUAAAAAM9hcosBoqQI4ZFKz_i0j_vW8r21"
                                        ref={this.recaptchaRef}
                                        onChange={this.captchaChange}
                                        hl={"hr"}  
                                        style={{marginTop : 20}}                                                 
                                     />
                                 </div>

                                 <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}  disabled={!this.state.recaptcha}>
                                    Prijavi se
                                </Button>
                            </form>
                        </div>
                        <div className="centered-container-row" style={{marginBottom : 20}}>
                            <div className="">
                                <a onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.edume", "_blank")}
                                    href="javascript:void(0)"> 
                                    <img src={google_play} width="141" height="42"/>
                                </a>
                            </div>
                            <div className="">
                                <a onClick={()=> window.open("https://apps.apple.com/us/app/e-dnevnik-me/id1506663957?ls=1", "_blank")}
                                    href="javascript:void(0)"> 
                                    <img src={app_store} height="42"/>
                                </a>
                            </div>
                        </div>
                    </Paper>
    			</Grid>
                <Hidden smDown>
                    <div className="footer-fixed-login">
                        <div style={{marginLeft : 10}}>{"Copyright © Ministarstvo prosvjete Crne Gore 2020"}</div>
                        <div className="centered-container-row">
                            <div style={{marginRight : 10, marginTop : 3}}>{"U saradnji sa "}</div>
                            <a href="http://www.telekom.me" style={{margin : 0}} className="centered-container-row">
                                <img src={LogoImg} style={{marginRight : 10}}/>
                            </a>
                        </div>
                    </div>
                </Hidden>
                <Hidden mdUp>    
                    <div className="footer-fixed-login-mob">
                        <div style={{marginLeft : 10, fontSize : 10}}>{"Copyright © Ministarstvo prosvjete Crne Gore 2020"}</div>
                        <div className="centered-container-row">
                            <div style={{marginRight : 10, marginTop : 3, fontSize : 10}}>{"U saradnji sa "}</div>
                            <a href="http://www.telekom.me" style={{margin : 0}} className="centered-container-row">
                                <img src={LogoImgSmall} style={{marginRight : 10}}/>
                            </a>
                        </div>
                    </div>
                </Hidden>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    return {
        loggedIn, user
    };
}


const connectedLogin = withStyles(styles, { withTheme: true})(connect(mapStateToProps)(Login));
export {connectedLogin as Login}; 