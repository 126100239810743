import React, { Component } from 'react';
import './SubjectIcon.css'
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareRootAlt, faAtom, faBook, faLandmark, faVolleyballBall, faWrench, faMusic} from '@fortawesome/free-solid-svg-icons';
import { faPaintBrush, faAtlas, faDna, faFlask, faDesktop, faHistory } from '@fortawesome/free-solid-svg-icons';

export class SubjectIcon extends Component {
	constructor(props) {
        super(props);  
    }

    getFirst = (input) => {
        /*if (!input){
            return "";
        }*/
        return input.substring(0, 1).toUpperCase();
    }
    
    jeSpec = (id) =>
    {
        if (!id){
            return false;
        }
        if (id == '1' || id == '5' || id == '99' || id == '7' || id == '2')
            return true;
        
        if (id == '802' || id == '2324' || id == '2323' || id == '2330' || id == '2331'|| id == '2332')
            return true;
        
        if (id == '101' || id == '8' || id == '3'|| id == '13')
            return true;
        
        if (id == '9' || id == '8030')
            return true;

        return false;
    }

    getSpecIcon = (id) =>
    {
    	if (id == 1)
    		return <FontAwesomeIcon icon={faSquareRootAlt} size="1x"/>
    	else if (id == 2)
    		return <FontAwesomeIcon icon={faAtom} size="1x"/>
    	else if (id == 5)
    		return <FontAwesomeIcon icon={faBook} size="1x"/>
    	else if (id == 7)
    		return <FontAwesomeIcon icon={faLandmark} size="1x"/>
    	else if (id == 99)
    		return <FontAwesomeIcon icon={faVolleyballBall} size="1x"/>
    	else if (id == '802' || id == '2330')
    		return <FontAwesomeIcon icon={faWrench} size="1x"/>
    	else if (id == '2324')
    		return <FontAwesomeIcon icon={faMusic} size="1x"/>
    	else if (id == '2323')
    		return <FontAwesomeIcon icon={faPaintBrush} size="1x"/>
    	else if (id == '2331')
    		return <FontAwesomeIcon icon={faPaintBrush} size="1x"/>
        else if (id == '101' )
            return <FontAwesomeIcon icon={faAtlas} size="1x"/>
    	else if (id == '2332' || id == '8' )
    		return <FontAwesomeIcon icon={faDna} size="1x"/>
    	else if (id == '3' )
    		return <FontAwesomeIcon icon={faFlask} size="1x"/>
    	else if (id == '9' || id == '8030')
    		return <FontAwesomeIcon icon={faDesktop} size="1x"/>
        else if (id == '13' )
            return <FontAwesomeIcon icon={faHistory} size="1x"/>
    }

    render() {    	
    	const jeSpec = this.jeSpec(this.props.id);
    	const specIcon = this.getSpecIcon(this.props.id);
    	
       	return (
       		<div className="icon-container">
       		{jeSpec &&
       			<div className="icon-style">{specIcon}</div>
       		}
       		{!jeSpec && 
       			<div className="icon-style">{this.getFirst(this.props.naziv)}</div> 
       		}
        	</div>

        ); 
    }
}