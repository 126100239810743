import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { withTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { faUsers, faCheck, faCoffee, faArrowLeft, faBook, faHandshake, faBalanceScale, faFileSignature, faGraduationCap, faAward, faEnvelope, faSignOutAlt, faUserGraduate, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import LogoImg from '../Main/img/T-logo-3.png';
import { Tooltip } from '@material-ui/core';
import dataService from '../Main/DataService';
import {Spiner} from '../Spiner/Spiner';
import {success, failure, setClasses, logout, setCurrClass} from '../_actions/actions';
import {userConstants} from '../_constants/user.constants';
import {AddNotif} from './AddNotif';
import {Notification} from '../Main/Notifications/Notification';
import axios from 'axios';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NotifIcon from '@material-ui/icons/NotificationImportant';
import Moment from 'moment';
import './Admin.css';

const styles = theme => {	
	return {
		root: {
	    	display: 'flex',
	  	},
	    appBar: {
		    zIndex: theme.zIndex.drawer + 1,
		},
		footerBar: {
		    zIndex: 10001,
		    border: '1px solid black', 
		    flexGrow: 1,
		},
	    list: {
		    width: 280,
		},
		fullList: {
		    width: 'auto',
		},
		content: {
			minHeight : 'calc(100vh - 132px)',
    		flexGrow: 1,
    		[theme.breakpoints.up('md')]: {
    			padding: theme.spacing(2),
    		}
  		},
  		menuButton: {
    		marginRight: theme.spacing(2),
  		},
  		title: {
    		display: 'flex',
    		flexGrow: 1,
    		justifyContent : 'flex-start',
    		alignItems : 'flex-start'
  		},
  		progress: {
			margin: theme.spacing(2),
		},
		nested: {
    		paddingLeft: theme.spacing(4),
  		},
  		toolbar: theme.mixins.toolbar,
	};
};

class Admin extends React.Component {

	constructor(props) {
        super(props); 
        this.state = {notifications : [], razredi : [], current_info : {id : null}, mode : 'list'};
    }

    componentDidMount() {
		this.loadNotifications();
	}

	async loadNotifications(){
		this.setState({loading : true});

		setTimeout( async () => {
			let server_data = await dataService.loadAllNotifications();	
			//console.log('server_data', server_data);
			this.setState({loading : false});
			if (server_data) this.setState({notifications : server_data.notifications, razredi : server_data.razredi}); 	
		}, 500);
	}

	handleLogout = () => {
		localStorage.setItem(userConstants.MEIS_EDU_TOKEN, null);		
    	const { dispatch } = this.props;
        //e.preventDefault();
        dispatch(logout());
		dispatch(setClasses());
		
		this.props.history.push("/login");
    }

    onNotifSave = (data) => {
    	this.setState({ loading: true });
       
        axios.post('/api/save_notif', data)
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});
            //console.log('server_data', server_data);
            if (server_data.msg == ''){                
                this.loadNotifications();
                this.setState({current_info : {id : null}, mode : 'list'});
            }
            else {
            	
            }
        }	
        ,error => {
        	this.setState({ loading: false});
        });
    }

    getMjesec = (ts) => {
		let datum = new Date(ts);
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (ts) => {
		let datum = new Date(ts);
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getDatum = (ts) => {
		let datum = new Date(ts);
		if (!datum) return '';
		return Moment(datum).format('DD.MM.YYYY hh:ss');
	}

	handleAdd = () => {
    	this.setState({current_info : {id : null}, mode : 'edit'});
    }

    handleBack = () => {
    	this.setState({current_info : {id : null}, mode : 'list'});
    }

    handleEdit = (data) => {
    	this.setState({current_info : data, mode : 'edit'});
    }

    handleDelete = (data) => {
    	this.setState({ loading: true });
       
        axios.post('/api/delete_notif', data)
        .then(response => {
            let server_data = response.data;
            this.setState({ loading: false});
            //console.log('server_data', server_data);
            if (server_data.msg == ''){                
                this.loadNotifications();
                this.setState({current_info : {id : null}, mode : 'list'});
            }
            else {
            	
            }
        }	
        ,error => {
        	this.setState({ loading: false});
        });
    }

    handleNotif = (data) => {
    	this.setState({ loading: true });
       	
       	setTimeout(() => {
	        axios.post('/api/send_notif', data)
	        .then(response => {
	            let server_data = response.data;
	            this.setState({ loading: false});
	            //console.log('server_data', server_data);
	        }	
	        ,error => {
	        	this.setState({ loading: false});
	        });
	    }, 1000);
    }

	render() {    
		const { container, classes } = this.props;

    	return (
    		<div>
	        	<div>
	        		<AppBar position="fixed" className={classes.appBar}>
				        <Toolbar>
				        	<Hidden smDown>
	          					<Typography variant="h6" className={classes.title}>
	          					{"ADMINISTRACIJA DNEVNIKA"}
	          					</Typography>
	          				</Hidden>

          					<Hidden smDown>
          						<div style={{marginRight : 10}}>
					            	{this.props.user}
								</div>
          						<div style={{marginRight : 20}}>
          							
	          						<FontAwesomeIcon icon={faUserGraduate} size="2x"/>
								</div>

								<div>
									<Tooltip title="Odjavi se" arrow>
		          						<Button color="inherit" onClick={event => this.handleLogout()}>
		          							<FontAwesomeIcon icon={faPowerOff} size="2x" />
		          						</Button>
		          					</Tooltip>
								</div>
          					</Hidden>
        				</Toolbar>
				     </AppBar>
					
					{this.state.loading &&
						<Spiner/>
					}

	        		<main className={classes.content}>
	        			
        				<div className={classes.toolbar} />

        				{(this.state.mode == 'list' ) &&
		        		<div className="admin-notif-header">
		        			<div><Typography variant="h5">Pregled obavještenja</Typography></div>
		        			<div>
		        				<Fab size="small" color="primary" aria-label="add" onClick={this.handleAdd}>
						        	<AddIcon />
						      </Fab>
		    				</div>
		        		</div>
		        		}
		        		{(this.state.mode == 'edit' ) &&
		        		<div className="admin-notif-header">
		        			<div><Typography variant="h5">Dodaj obavještenje</Typography></div>
		        			<div>
		        				<Fab size="small" color="primary" aria-label="add" onClick={this.handleBack}>
						        	<FontAwesomeIcon icon={faArrowLeft} size="1x"/>
						      </Fab>
		    				</div>
		        		</div>
		        		}
		        		{(this.state.mode == 'list' ) &&
		        		<div>
		        			{this.state.notifications.map((item, index) =>
					            <ExpansionPanel>
									<ExpansionPanelSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										>
										<Typography component="h2">{this.getDatum(item.ts)  + " - " + item.headline}</Typography>
											
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<div className="right-flex-container">
										<div className="admin-toolbal-header"> 
											<Fab size="small" color="secondary" aria-label="add" style={{marginLeft : 5}}
											onClick={event => this.handleDelete(item)}>
									        	<DeleteIcon />
									      	</Fab>
											<Fab size="small" color="primary" aria-label="add" style={{marginLeft : 5}}
												onClick={event => this.handleEdit(item)}>
									        	<EditIcon />
									      	</Fab>
									      	<Fab size="small" color="primary" aria-label="add" style={{marginLeft : 5}}
									      		onClick={event => this.handleNotif(item)}>
									        	<NotifIcon />
									      </Fab>
										</div>
										<div className="bottom-flex-container w-100"  style={{marginTop : 5}}>
											<div className="w-100" dangerouslySetInnerHTML={{__html: item.body}} />
										</div>
										</div>
									</ExpansionPanelDetails>
								</ExpansionPanel>
							)}
		        		</div>
		        		}
		        		{(this.state.mode == 'edit' ) &&
		        		<AddNotif onSave={this.onNotifSave} data={this.state.current_info} razredi={this.state.razredi}></AddNotif>
		        		}
			        </main>
			        <div style={{height : 50}}></div>
			        
            	</div>

            	<div className="admin-footer centered-container" style={{zIndex : 999999999}}>
            		<div style={{marginLeft : 10}}>
			        	{"Copyright © Ministarstvo prosvjete Crne Gore 2020"}
			        </div>
			        <div className="centered-container-row">
                        <div style={{marginRight : 10, marginTop : 0}}>{"U saradnji sa "}</div>
                        <a href="http://www.telekom.me" style={{margin : 3}} className="centered-container-row">
                            <img src={LogoImg} style={{marginRight : 10}}/>
                        </a>
                    </div>
			     </div>
	        </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    return {
        loggedIn, user
    };
}


/*Main.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired
};*/

/**/
const connecteAdmin = withStyles(styles, { withTheme: true})(connect(mapStateToProps)(Admin));
export {connecteAdmin as Admin}; 