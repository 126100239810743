import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
export default class MarkTypeSelector extends Component {
	constructor(props) {
		super(props);  
	}

	handleChange = (event) => {
		if (event && event.target && event.target.value && this.props.onChange){
			this.props.onChange(event.target.value);
		}
	}

	render() {
  		return (
  			<div>
				<Hidden mdUp>
	        		<FormControl ariant="outlined" style={{width : '100%', marginBottom : 20, marginTop : 5}}>
						<InputLabel htmlFor="mark-type-helper" style={{fontWeight : 'bold'}}>Odaberite tip ocjene</InputLabel>
						<Select value={this.props.type_id} onChange={this.handleChange} input={<Input name="mark-type" id="mark-type-helper" />}>
							{this.props.mark_types.map((type, index) =>		
								<MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
							)}
						</Select>
					</FormControl>
				</Hidden>
				<Hidden smDown>
					<Grid container spacing={0}>
        				<Grid item md={8} className="centered-container-row ">
        					
							<FormControl ariant="outlined" style={{width : '100%', marginBottom : 20, marginTop : 5, marginLeft : 0}}>	
									<InputLabel htmlFor="mark-type-helper" style={{fontWeight : 'bold'}}>Odaberite tip ocjene</InputLabel>	
									<Select value={this.props.type_id} onChange={this.handleChange} input={<Input name="mark-type" id="mark-type-helper" />}>
										{this.props.mark_types.map((type, index) =>		
											<MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
										)}
									</Select>						
							</FormControl>
							
						</Grid>
					</Grid>
				</Hidden>
			</div>

		);
  	}
}