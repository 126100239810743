import React, { Component } from 'react';
import './Main.css'
import { connect } from 'react-redux';
import axios from 'axios';
//import {history} from '../_helpers/history';
import { store } from '../_helpers/store';
import { MainHeader } from './MainHeader/MainHeader';
import { Marks } from './Marks/Marks';
import { MarksMUI } from './Marks/MarksMUI'
import { MarksDetails } from './MarksDetails/MarksDetails';
import { Conduct } from './Conduct/Conduct';
import { Attendance } from './Attendance/Attendance';
import { Discipline } from './Discipline/Discipline';
import { ExtTest } from './ExtTest/ExtTest';
import { ParentCoop } from './Parents/ParentCoop';
import { Notifications } from './Notifications/Notifications';
import { Contact } from './Contact/Contact';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {Spiner} from '../Spiner/Spiner';
import dataService from './DataService';
import {success, failure, setClasses, logout, setCurrClass} from '../_actions/actions';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { withTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { faUsers, faCheck, faCoffee, faArrowLeft, faBook, faHandshake, faBalanceScale, faFileSignature, faGraduationCap, faAward, faEnvelope, faSignOutAlt, faUserGraduate, faPowerOff, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import {userConstants} from '../_constants/user.constants';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MarksDetailsMUI from './Marks/MarksDetailsMUI'
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import BackgroundHeder from './header.png';
import MemoImg from './img/memo.png';
import LogoImg from './img/T-logo-3.png';
import { Tooltip } from '@material-ui/core';

const drawerWidth = 280;

const styles = theme => {	
	return {
		root: {
	    	display: 'flex',
	  	}
	  	,
	  	drawer: {
    		width: drawerWidth,
    		flexShrink: 0,
  		},
	    appBar: {
		    zIndex: theme.zIndex.drawer + 1,
		},
		footerBar: {
		    zIndex: 10001,
		    border: '1px solid black', 
		    flexGrow: 1,
		},
	    list: {
		    width: 280,
		},
		fullList: {
		    width: 'auto',
		},
		drawerPaper: {
    		width: drawerWidth,
  		},
  		content: {
    		flexGrow: 1,
    		[theme.breakpoints.up('md')]: {
    			padding: theme.spacing(2),
    		}
  		},
  		menuButton: {
    		marginRight: theme.spacing(2),
  		},
  		title: {
    		display: 'flex',
    		flexGrow: 1,
    		justifyContent : 'flex-start',
    		alignItems : 'flex-start'
  		},
  		progress: {
			margin: theme.spacing(2),
		},
		drawerShift: {
    		width: `calc(100% - ${drawerWidth}px)`,
    		marginLeft: drawerWidth
    	},
    	nested: {
    		paddingLeft: theme.spacing(4),
  		},
  		toolbar: theme.mixins.toolbar,
  		sideTop : {
  			height : '75px',
  			//backgroundColor : '#03a9f4',
  			backgroundImage: `url(${BackgroundHeder})`, 
  			display: 'flex',
  			displayOrientation : 'row',
  			justifyContent : 'flex-start',
  			alignItems : 'center',
	    	padding : '10px',
  		}
	};
};

class Main extends React.Component {

	constructor(props) {
        super(props);  

        this.state = {tabKey : 'marks', 
        loading : false, 
        marks: [], marks_details: [], markTypes : [], 
        conduct : [], conduct_loaded : false,
        attendance : [], attendance_loaded : false, 
        discipline : [], discipline_loaded : false, 
        ext_test : [], ext_test_loaded : false, 
        parent_coop : [], parent_coop_loaded : false, 
        notifications : [], notifications_loaded : false, 
        left : false, 
    	selected_key : 'marks', anchorEl : null, type_id : -1, 
    	subject_selected : false, 
    	subject_name : '', 
    	title : 'Ocjene', 
    	class_open : false};
        this.handleOnRowSelected = this.handleOnRowSelected.bind(this);
        this.handleOnOptionSelected = this.handleOnOptionSelected.bind(this);
    }

	componentDidMount() {
		this.loadUser();
	}

	loadUser(){
		//console.log('loading user ', this.props.class_id);
		const { dispatch } = this.props;
		this.setState({loading : true});

		axios.get('/api/getuser')
		.then((response) => {              
			this.setState({loading : false});
			let user = response.data;
			//console.log(user);
			dispatch(success(user.username));
			dispatch(setClasses(user.classes));
			this.setState({'marks' : user.marks}); 			
			this.setState({markTypes : user.markTypes});
			//console.log(this.props);
			if (user.is_admin){
				this.props.history.push("/admin");
			}
		})
		.catch((error) => {  
			this.setState({loading : false});      
			try 
			{
				this.props.history.push("/login");
			}
			catch(error){
			}
		});
	}

	async loadMarks(class_id){
		this.setState({loading : true});
		setTimeout( async () => {
			let marks = await dataService.loadMarks(class_id);		
			this.setState({loading : false});
			this.setState({'marks' : marks}); 			
		}, 500);
		
	}

	loadNewClass = (class_id) =>
	{
		this.setState ({marks_details: [],
        conduct : [], conduct_loaded : false,
        attendance : [], attendance_loaded : false, 
        discipline : [], discipline_loaded : false, 
        parent_coop : [], parent_coop_loaded : false, 
        left : false, 
    	selected_key : 'marks', anchorEl : null, type_id : -1, 
    	subject_selected : false, 
    	subject_name : '', 
    	title : 'Ocjene', 
    	class_open : false});

    	this.loadMarks(class_id);	
	}

	handleLogout = () => {
		localStorage.setItem(userConstants.MEIS_EDU_TOKEN, null);		
    	const { dispatch } = this.props;
        //e.preventDefault();
        dispatch(logout());
		dispatch(setClasses());
		
		this.props.history.push("/login");
    }

	async loadConduct(){
		if (this.state.conduct_loaded) return; 		
		await this.setState({conduct_loaded : true});

		this.setState({loading : true});

		setTimeout( async () => {
			let conduct = await dataService.loadConduct(this.props.class_id);		
			this.setState({loading : false});
			if (conduct) this.setState({conduct : conduct}); 	
		}, 500);
	}

	async loadAttendance(){
		if (this.state.attendance_loaded) return; 		
		await this.setState({attendance_loaded : true});

		this.setState({loading : true});

		setTimeout( async () => {
			let attendance = await dataService.loadAttendance(this.props.class_id);	
			this.setState({loading : false});
			if (attendance) this.setState({attendance : attendance}); 		
		}, 500);
	}

	async loadDiscipline(){
		if (this.state.discipline_loaded) return; 		
		await this.setState({discipline_loaded : true});

		this.setState({loading : true});

		setTimeout( async () => {
			let discipline = await dataService.loadDiscipline(this.props.class_id);		
			//console.log('discipline_loaded', discipline);
			this.setState({loading : false});
			if (discipline) this.setState({discipline : discipline}); 		
		}, 500);
	}

	async loadExtTest(){
		if (this.state.ext_test_loaded) return; 		
		await this.setState({ext_test_loaded : true});

		this.setState({loading : true});

		setTimeout( async () => {
			let ext_test = await dataService.loadExtTest(this.props.class_id);		
			//console.log('ext_test', ext_test);
			this.setState({loading : false});
			if (ext_test) this.setState({ext_test : ext_test}); 		
		}, 500);
	}

	async loadNotifications(){
		if (this.state.notifications_loaded) return; 		
		await this.setState({notifications_loaded : true});

		this.setState({loading : true});

		setTimeout( async () => {
			let notifications = await dataService.loadNotifications(this.props.class_id);		
			
			this.setState({loading : false});
			if (notifications) this.setState({notifications : notifications}); 		
		}, 500);
	}

	async loadParentCoop(){
		if (this.state.parent_coop_loaded) return; 		
		await this.setState({parent_coop_loaded : true});

		this.setState({loading : true});

		setTimeout( async () => {
			let parent_coop = await dataService.loadParentCoop(this.props.class_id);		
			//console.log('parent_coop', parent_coop[0]);
			this.setState({loading : false});
			if (parent_coop) this.setState({parent_coop : parent_coop[0]}); 		
		}, 500);
	}

	/*<MainHeader></MainHeader>*/
	toggleDrawer = (side, open) => event => {
	    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
	      return;
	    }

	    this.setState({ ...this.state, [side]: open });
	};
	//onClick={this.toggleDrawer(side, false)}
	popupList = side => (
	    <div
	      className={this.props.classes.list}
	      role="presentation"	      
	      onKeyDown={this.toggleDrawer(side, false)}
	    >
	    	<div className={this.props.classes.sideTop}>
	    		<div style={{color : 'white', marginRight : '20px', marginLeft : '10px'}}><FontAwesomeIcon icon={faUserGraduate} size="2x"/></div>	
	    		<div style={{color : 'white', fontWeight : 'bold'}}>{this.props.user}</div>
	    		
	    	</div>
			{this.getList(true)}
	        <Divider/>
	        {this.getLogoutList()}
	        <Divider/>
	        {this.getClassList()}
	        {this.getNVULogo()}
	        
	    </div>
	);

	handleOnOptionSelected(key, is_popup){	
		
		this.setState({ selected_key : key});	
		if (key == 'marks')
			this.setState({subject_selected : false, title : 'Ocjene'});	
		else if (key == 'conduct'){
			this.loadConduct();
			this.setState({title : 'Vladanje'});	
		}
		else if (key == 'attendance'){
			this.loadAttendance();
			this.setState({title : 'Izostanci'});
		}
		else if (key == 'discipline'){
			this.loadDiscipline();
			this.setState({title : 'Vaspitne mjere'});
		}
		else if (key == 'ext_test'){
			this.loadExtTest();
			this.setState({title : 'Eksterna testiranja'});
		}
		else if (key == 'notifications'){
			this.loadNotifications();
			this.setState({title : 'Informacije'});
		}
		else if (key == 'parent_coop'){
			this.loadParentCoop();
			this.setState({title : 'Saradnja sa roditeljima'});
		}
		else if (key == 'contact'){
			this.setState({title : 'Kontakt'});
		}
		else if (key == 'logout')
			this.handleLogout();

		if (is_popup)
			this.setState({ left: false });
	}

	getList = (is_popup) =>
	(
		<List>
			<ListItem button key={'marks'} selected={this.state.selected_key === 'marks'} 
			onClick={event => this.handleOnOptionSelected('marks', is_popup)}>
             	<ListItemIcon className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faGraduationCap} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Ocjene'} />
            </ListItem>
            <ListItem button key={'attendance'} selected={this.state.selected_key === 'attendance'} 
			onClick={event => this.handleOnOptionSelected('attendance', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faBook} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Izostanci'} />
            </ListItem>
            <ListItem button key={'conduct'} selected={this.state.selected_key === 'conduct'} 
			onClick={event => this.handleOnOptionSelected('conduct', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faFileSignature} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Vladanje'} />
            </ListItem>
            <ListItem button key={'discipline'} selected={this.state.selected_key === 'discipline'} 
			onClick={event => this.handleOnOptionSelected('discipline', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faBalanceScale} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Vaspitne mjere'} />
            </ListItem>
            <ListItem button key={'ext_test'} selected={this.state.selected_key === 'ext_test'} 
			onClick={event => this.handleOnOptionSelected('ext_test', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faAward} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Eksterna testiranja'} />
            </ListItem>
            <ListItem button key={'parent_coop'} selected={this.state.selected_key === 'parent_coop'} 
			onClick={event => this.handleOnOptionSelected('parent_coop', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faHandshake} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Saradnja sa roditeljima'} />
            </ListItem>
            <ListItem button key={'notifications'} selected={this.state.selected_key === 'notifications'} 
			onClick={event => this.handleOnOptionSelected('notifications', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faInfoCircle} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Informacije'} />
            </ListItem>
            <ListItem button key={'contact'} selected={this.state.selected_key === 'contact'} 
			onClick={event => this.handleOnOptionSelected('contact', is_popup)}>
             	<ListItemIcon  className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faEnvelope} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Kontakt'} />
            </ListItem>
        </List>
	)

	getLogoutList = () =>
	(
        <List>
        	<ListItem button key={'logout'} selected={this.state.selected_key === 'logout'} 
			onClick={event => this.handleOnOptionSelected('logout')}>
             	<ListItemIcon className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faPowerOff} size="1x"/></ListItemIcon>
             	<ListItemText primary={'Odjavi se'} />
            </ListItem>
        </List>
	)

	getLogo = () => {
		return (
			<div style={{marginTop : 20}} className="centered-container">
				<img src={MemoImg} />
				<Typography variant="h6">{"MINISTARSTVO PROSVJETE"}</Typography>
			</div>
		);
	}

	getNVULogo = () =>{
			return (
			<div style={{marginTop : 0, fontSize : 14, fontStyle : 'italic', flexGrow : 1}} className="centered-container">
             	<div>{'Inicijativa NVU "Učionica u pokretu"'}</div>
            </div>
            );
	}

	sideList = () => (
		<Drawer
	        className={this.props.classes.drawer}
	        variant="permanent"
	        classes={{
	          paper: this.props.classes.drawerPaper,
	        }}
	     >
	        <div className={this.props.classes.toolbar} />
	        {this.getList(false)}
	        <Divider/>
	        {this.getLogoutList()}
	        <Divider/>
	        {this.getClassList()}
	        {this.getNVULogo()}
      	</Drawer>
	)

	getClassList = () =>
	(
        <List>
        	<ListItem button key={'class'} selected={this.state.selected_key === 'class'} 
				onClick={event => this.handleOnClassClick()}>
             	<ListItemIcon className="centered-container-row icon-left-margin"><FontAwesomeIcon icon={faUsers} size="1x"/></ListItemIcon>
             	<ListItemText primary={this.props.class_name} />
             	{(this.props.class_data && this.props.class_data.length > 1 && this.state.class_open) &&
             		<ExpandLess /> 
             	}
             	{(this.props.class_data && this.props.class_data.length > 1 && !this.state.class_open) &&
             		<ExpandMore />
             	}
            </ListItem>
            {(this.props.class_data && this.props.class_data.length > 1) &&
            <Collapse in={this.state.class_open} timeout="auto" unmountOnExit>
		        <List component="div" disablePadding>
		        	{this.props.class_data.map((item, index) =>
					<ListItem button className={this.props.classes.nested} key={item.id}
						onClick={event => this.handleOnClassClick(item)}>
						{(this.props.class_id == item.id) &&
							<ListItemIcon><FontAwesomeIcon icon={faCheck}/></ListItemIcon>
						}
						<ListItemText primary={item.naziv}/>
					</ListItem>
					)}
		        </List>
	      </Collapse>}
        </List>
	)

	handleOnClassClick(item){
		this.setState({class_open : !this.state.class_open});
		//console.log(item);
		if (item){
			this.props.dispatch(setCurrClass(item.id, this.props.class_data));
			this.loadNewClass(item.id);
		}
	}

	onChangeType = (type_id) =>{		
		let marks =[];
		for(let i = 0; i < this.state.marks.length; i++)
			marks.push(this.state.marks[i]);

		for(let i = 0; i < marks.length; i++){
			let mark = marks[i];
			if (mark.ocjene){
				mark.ocjena = '';
				mark.datum = '';
				mark.nazivtipa = '';
				if (type_id == -1){
					if ( mark.ocjene.length > 0){
						let ocjena = mark.ocjene[0];					
						mark.ocjena = ocjena.ocjena;
						mark.datum = ocjena.datum;
						mark.nazivtipa = ocjena.nazivtipa;	
						mark.naziv_cilja = ocjena.naziv_cilja;					
					}
				}
				else
				{
					for(let j = 0; j < mark.ocjene.length; j++){
						let ocjena = mark.ocjene[j];
						if (ocjena.id_tipa_ocjene == type_id){
							mark.ocjena = ocjena.ocjena;
							mark.datum = ocjena.datum;							
							mark.nazivtipa = ocjena.nazivtipa;
							mark.naziv_cilja = ocjena.naziv_cilja;					
						}
					}
				}
			}
			this.setState({marks : marks, type_id : type_id});
		}
	}

	handleOnRowSelected(data){
		this.setState({'marks_details' : data.ocjene, subject_selected : true, subject_name : data.naziv});
	}

	handleSubjectBack = () =>{
		this.setState({subject_selected : false});
	}
	/**/
	getContent = (classes) => (
		<div>
			{(this.state.selected_key == 'marks' && !this.state.subject_selected) &&
				<Slide in={this.state.selected_key == 'marks' && !this.state.subject_selected} style={{ transitionDelay: '300ms' }}>
					<MarksMUI marks={this.state.marks} onRowSelected={this.handleOnRowSelected} onChangeType={this.onChangeType} type_id={this.state.type_id} mark_types={this.state.markTypes}/>	
	        	</Slide>
	        }
	        {(this.state.selected_key == 'marks' && this.state.subject_selected) &&
	        	<Slide  in={this.state.selected_key == 'marks' && this.state.subject_selected} style={{ transitionDelay: '300ms' }}>
	        		<MarksDetailsMUI marks={this.state.marks_details} subject_name={this.state.subject_name} onBack={this.handleSubjectBack}/>	
	        	</Slide>
	        }
	        {this.state.selected_key == 'attendance' &&
	        	<Slide in={this.state.selected_key == 'attendance'} style={{ transitionDelay: '300ms' }}>
	        		<Attendance attendance={this.state.attendance}/>	
	        	</Slide>
	        }
	        {this.state.selected_key == 'conduct' &&
	        	<Slide in={this.state.selected_key == 'conduct'} style={{ transitionDelay: '300ms' }}>
	        		<Conduct conduct={this.state.conduct}/>	
	        	</Slide>
	        }
	        {this.state.selected_key == 'discipline' &&
	        	<Slide in={this.state.selected_key == 'discipline'} style={{ transitionDelay: '300ms' }}>
	        		<Discipline discipline={this.state.discipline}/>
	        	</Slide>
	        }
	        {this.state.selected_key == 'ext_test' &&
	        	<Slide in={this.state.selected_key == 'ext_test'} style={{ transitionDelay: '300ms' }}>
	        		<ExtTest marks={this.state.ext_test}/>
	        	</Slide>
	        }
	        {this.state.selected_key == 'parent_coop' &&
	        	<Slide in={this.state.selected_key == 'parent_coop'} style={{ transitionDelay: '300ms' }}>
	        		<ParentCoop parent_coop={this.state.parent_coop}/>
	        	</Slide>
	        }
	        {this.state.selected_key == 'notifications' &&
	        	<Slide in={this.state.selected_key == 'notifications'} style={{ transitionDelay: '300ms' }}>
	        		<Notifications notifications={this.state.notifications}/>
	        	</Slide>
	        }
	        {this.state.selected_key == 'contact' &&
	        	<Slide in={this.state.selected_key == 'contact'} style={{ transitionDelay: '300ms' }}>
	        		<Contact class_id={this.props.class_id}/>
	        	</Slide>
	        }
		</div>
	)

	handleMenu = (event) => {
    	this.setState({anchorEl : event.currentTarget});
  	}

  	handleClose = () => {
    	this.setState({anchorEl : null});
  	}

  	handleOnSubjectBack = () => {
		this.setState({subject_selected : false});
	}

	getMobileTitle = () => {
		if (this.state.selected_key == 'marks' && this.state.subject_selected)
			return this.state.subject_name;
		else 
			return "DNEVNIK";
	}

    render() {    
		const { container, classes } = this.props;

    	return (
    		<div>
	        	<div>
	        		<AppBar position="fixed" className={classes.appBar}>
				        <Toolbar>
				        	<Hidden smDown>
	          					<Typography variant="h6" className={classes.title}>
	          					{"DNEVNIK"}
	          					</Typography>
	          				</Hidden>
				        	<Hidden mdUp>
	          					<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer('left', true)}>
	            					<MenuIcon />
	          					</IconButton>
	          				</Hidden>
	          				
	          				<Hidden mdUp>
	          					<Typography variant="h6" className={classes.title} noWrap>
	          					{this.getMobileTitle()}
	          					</Typography>	          				
	          				</Hidden>

          					<Hidden smDown>
          						<div style={{marginRight : 10}}>
					            	{this.props.user}
								</div>
          						<div style={{marginRight : 20}}>
          							
	          						<FontAwesomeIcon icon={faUserGraduate} size="2x"/>
								</div>

								<div>
									<Tooltip title="Odjavi se" arrow>
		          						<Button color="inherit" onClick={event => this.handleOnOptionSelected('logout')}>
		          							<FontAwesomeIcon icon={faPowerOff} size="2x" />
		          						</Button>
		          					</Tooltip>
								</div>
          					</Hidden>
          					<Hidden mdUp>
          						{(this.state.selected_key == 'marks' && this.state.subject_selected) &&
		        					<IconButton cria-label="Nalog" aria-controls="menu-appbar" aria-haspopup="true" color="inherit"
		        						onClick={this.handleOnSubjectBack}>
		        						<FontAwesomeIcon icon={faArrowLeft} size="1x"/>
		        					</IconButton>
		        				}
	        				</Hidden>
        				</Toolbar>
				     </AppBar>
				     <Hidden smDown>
				     	{this.sideList()}
				     </Hidden>

	        		<SwipeableDrawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
			        	{this.popupList('left')}
			      	</SwipeableDrawer>

					
					{this.state.loading &&
						<Spiner/>
					}

	        		<main className={classes.content}>
	        			
        				<div className={classes.toolbar} />
		        		<Hidden smDown>
			        		<div className={classes.drawerShift}>
			        		{this.getContent(classes)}
			        		</div>
		        		</Hidden>

		        		<Hidden mdUp>
			        		<div>
			        		{this.getContent(classes)}
			        		</div>
		        		</Hidden>
			        </main>
			        <div style={{height : 50}}></div>
			        
            	</div>

            	<Hidden smDown>
	            	<div className="footer-fixed centered-container" style={{zIndex : 999999999}}>
	            		<div style={{marginLeft : 10}}>
				        	{"Copyright © Ministarstvo prosvjete Crne Gore 2020"}
				        </div>
				        <div className="centered-container-row">
	                        <div style={{marginRight : 10, marginTop : 0}}>{"U saradnji sa "}</div>
	                        <a href="http://www.telekom.me" style={{margin : 3}} className="centered-container-row">
	                            <img src={LogoImg} style={{marginRight : 10}}/>
	                        </a>
	                    </div>
				     </div>
			     </Hidden>
			     <Hidden mdUp>
			     	<div className="footer-fixed centered-container" style={{zIndex : 999999999}}>
	            		<div style={{marginLeft : 10, fontSize : 10}}>
				        	{"Copyright © Ministarstvo prosvjete Crne Gore 2020"}
				        </div>
				        <div className="centered-container-row">
	                        <div style={{marginRight : 10, marginTop : 0, fontSize : 10}}>{"U saradnji sa "}</div>
	                        <a href="http://www.telekom.me" style={{margin : 3}} className="centered-container-row">
	                            <img src={LogoImg} style={{marginRight : 10}}/>
	                        </a>
	                    </div>
				     </div>
			     </Hidden>
	        </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const { class_id, class_name, class_data } = state.school_classes;
    return {
        loggedIn, user, class_id, class_name, class_data
    };
}


/*Main.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired
};*/

/**/
const connectedMain = withStyles(styles, { withTheme: true})(connect(mapStateToProps)(Main));
export {connectedMain as Main}; 
/**/

//export default withStyles(styles)(Main);

/*const connectedMain = connect(mapStateToProps)(Main);
export {connectedMain as Main}; */
