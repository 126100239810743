import React, { Component } from 'react';
import Moment from 'moment';
import {Spiner} from '../../Spiner/Spiner';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SubjectIcon} from '../Marks/SubjectIcon/SubjectIcon';
import {MarkIcon} from '../Marks/MarkIcon/MarkIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Grid from '@material-ui/core/Grid';

export class ExtTest extends React.PureComponent {
	constructor(props) {
        super(props);  
    }

	componentDidMount() {		
	}


	getMjesec = (datum) => {
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (datum) => {
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getGodina = (datum) => {
		if (!datum) return '';
		return Moment(datum).format('YYYY');
	}

    render() {
    	const marks = this.props.marks;
  		
  		return (
  			
        	<div>
        		<Grid container spacing={3}>
					<Grid item sm={12} className="marks-grid-container">
			            <div className="marks-container">
				            <List style={{padding:'0px', margin : '0px'}}>			
								{marks.map((mark, index) =>					
									<ListItem button key={index} style={{padding:'0px', margin : '0px'}}>
										<div className="main-flex-container">
											<SubjectIcon naziv={mark.naziv_predmeta} id={mark.id_grupe}/>
											<div className="right-flex-container">
												<div className="upper-flex-container">
													<div className="main-text">
														{mark.naziv_predmeta}
													</div>										
												</div>
												<div className="bottom-flex-container">
													<div  className="centered-container"><MarkIcon mark={mark.ocjena}/></div>
													<div  className="centered-container">{mark.naziv_tipa}</div>
												</div>
											</div>
											<div className="date-container">
												<div>{this.getDan(mark.datum_upisivanja)}</div>
												<div>{this.getMjesec(mark.datum_upisivanja)}</div>
												<div>{this.getGodina(mark.datum_upisivanja)}</div>
											</div>
										</div>
									</ListItem>
								)}
							</List>
						</div>
					</Grid>
			     </Grid>
			</div>
        );
    }
}
