import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
ResponsiveContainer, LabelList, ComposedChart, Line, Area, Scatter} from 'recharts';
import PropTypes from 'prop-types';


const data = [
  {
    name: 'Page A', uv: 590, pv: 800, amt: 1400, cnt: 490,
  },
  {
    name: 'Page B', uv: 868, pv: 967, amt: 1506, cnt: 590,
  },
  {
    name: 'Page C', uv: 1397, pv: 1098, amt: 989, cnt: 350,
  },
  {
    name: 'Page D', uv: 1480, pv: 1200, amt: 1228, cnt: 480,
  },
  {
    name: 'Page E', uv: 1520, pv: 1108, amt: 1100, cnt: 460,
  },
  {
    name: 'Page F', uv: 1400, pv: 680, amt: 1700, cnt: 380,
  },
];
export default class PeriodChart extends PureComponent { 
    constructor(props) {
        super(props);  
    }
    getBarColor = (index) => {
        if (index == 0)
            return '#ff0000';

        if (index == 1)
            return '#ffa851';

        if (index == 2 || index == 3)
            return '#8b9dc3';
        
        if (index == 4)
            return '#328530';
    }

    getTitle(){
        return "Uspjeh po klasif. periodima";
    }
    //
    /*render() {
        return (
            <div style={{ width: '100%', height: 300 }}>
                <div>{this.getTitle()}</div>
                <ResponsiveContainer>
                    <ComposedChart
                        width={350}
                        height={200}
                        data={this.props.data.data}
                        margin={{
                          top: 20, right: 20, bottom: 20, left: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="period" />
                        <YAxis/>
                        <Tooltip />
                        
                        <Bar dataKey="prosjek" fill="#1976d2"  label={{position: 'top' }}>
                        </Bar>
                        <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }*/
    /*<Scatter name="A school" data={data} fill="#8884d8">
                          <LabelList dataKey="prosjek" />
                        </Scatter>*/

    render() {        
        return (
            <div style={{ width: '100%', height: 300 }}>
                <div>{this.getTitle()}</div>
                <ResponsiveContainer>
                    <ComposedChart
                        width={350}
                        height={200}
                        data={this.props.data}
                        margin={{
                          top: 20, right: 20, bottom: 20, left: 20,
                        }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="period" />
                    <YAxis domain={[0, 5]}/>
                    <Tooltip />  
                       <Bar dataKey="prosjek" barSize={20} fill="#0c99c1" label={{position: 'top' }}/>
                       <Line type="monotone" dataKey="prosjek" stroke="#ff7300" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
    );
  }
}
