import { userConstants } from '../_constants/user.constants';

const sc_initialState = { class_id: '', class_name: '', class_data : []};

export function school_classes(state = sc_initialState, action) {
    switch (action.type) {
        case userConstants.SET_CLASSES:        		
            return {class_id: action.class_id, class_name: action.class_name, class_data: action.class_data};
        case userConstants.CLEAR_CLASSES:        		
            return sc_initialState;
        default:
            return state;
    }
}