import React, { Component } from 'react';
import './MarksDetails.css'
import Moment from 'moment';

export class MarksDetails extends Component {

    constructor(props) {
        super(props);  
        this.state = {selKey : -1}  

        this.handleOnRowSelected = this.handleOnRowSelected.bind(this);
    }

	componentDidMount() {		
	}

	handleOnRowSelected(e, mark, index){	
		this.setState({selKey : index})
	}

    render() {
    	const marks = this.props.marks;
  		
  		const rows = marks.map((mark, index) =>
			<tr key={index} onClick={(e) => this.handleOnRowSelected(e, mark, index)} 
			style={{background : index == this.state.selKey? '#33b5e5' : null}}
			className="small-tr">
				<td align='left'>{mark.ocjena}</td>
				<td align='left'>{mark.nazivtipa}</td>
				<td align='left'>{Moment(mark.datum).format('DD.MM.YYYY')}</td>									
			</tr>
		 );
        return (
            <div className="row">
				<div className="col">
					<div className="table-responsive">
						<table
							className="table table-sm table-striped table-bordered table-hover">
							<thead>
								<tr align="left">
									<th>Ocjena</th>
									<th>Tip</th>
									<th>Datum</th>
								</tr>
							</thead>
							<tbody>
								{rows}
							</tbody>
						</table>
					</div>
				</div>
			</div>
        );
    }
}
