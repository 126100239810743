import React, { Component } from 'react';
import './MarksMUI.css'
import './MarksDetailsMUI.css'
import Moment from 'moment';
import {Spiner} from '../../Spiner/Spiner';
import CircularProgress from '@material-ui/core/CircularProgress';
import {TypeIcon} from './TypeIcon/TypeIcon';
import {MarkIcon} from './MarkIcon/MarkIcon';
import {MarkIconDesc} from './MarkIcon/MarkIconDesc';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CurrMarksChart from './CurrMarksChart';
import Grid from '@material-ui/core/Grid';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';

export default class MarksDetailsMUI extends Component {
	constructor(props) {
        super(props); 
    }

	componentDidMount() {		
	}

	getMjesec = (datum) => {
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (datum) => {
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getAvg(sum, cnt){
		if (cnt == 0)
			return '';

		return Math.round((sum / cnt) * 100) / 100;
	}
	
	getTekucaStatistika = (marks) =>{
		let data = [
	     	{ocjena: '1', "ukupan broj": 0},
	     	{ocjena: '2', "ukupan broj": 0},
	     	{ocjena: '3', "ukupan broj": 0},
	     	{ocjena: '4', "ukupan broj": 0},
	     	{ocjena: '5', "ukupan broj": 0}
		];
		let sum = 0; 
		let cnt = 0; 
		marks.map((mark, index) =>	{
			if (mark.ocjena == '1' || mark.ocjena == '2' || mark.ocjena == '3' || mark.ocjena == '4' || mark.ocjena == '5'){
				let ocjena = parseInt(mark.ocjena);
				data[ocjena - 1]["ukupan broj"]++;
				cnt++;
				sum += ocjena;
			}
		});
		return {data : data, avg : this.getAvg(sum, cnt)};
	}
	
	handleBack = () =>{
		if(this.props.onBack)
			this.props.onBack();
	}

    render() {
    	const marks = this.props.marks;
    	
  		return (
  			
        	<div>
        		<Hidden smDown>
	        		<Grid container spacing={3}>
	        			<Grid item md={8} className="marks-grid-container">
		        			<div className="mark-details-header">

			    				<div className="mark-details-subject">
			        				<Typography variant="h6">
			          					{this.props.subject_name}
			          				</Typography>          				
			          			</div>
		          			
			    				<div>
			        				<Fab color="primary" aria-label="Add" size="small" variant="contained" className="back-fab-marks-details"
			        				onClick={this.handleBack}>
			        					<FontAwesomeIcon icon={faArrowLeft} size="1x"/>
			        				</Fab>
			    				</div>
		    				</div>
	    				</Grid>
    				</Grid>
    			</Hidden>

        		<Grid container spacing={3}>
        			<Grid item md={8} className="marks-grid-container">
			            <div className="marks-container">
				            <List style={{padding:'0px', margin : '0px'}}>			
								{marks.map((mark, index) =>					
									<ListItem button key={index} style={{padding:'0px', margin : '0px'}}>
										<div className="main-flex-container">
											<TypeIcon naziv={mark.nazivtipa} id={mark.id_tipa_ocjene}/>
											<div className="right-flex-container">
												<div className="upper-flex-container">
													<div className="main-text">
														{mark.nazivtipa}
													</div>										
												</div>
												<div className="bottom-flex-container">
													{mark.naziv_cilja &&
														<MarkIconDesc mark={mark.ocjena}/>
													}
													{!mark.naziv_cilja &&
														<MarkIcon mark={mark.ocjena}/>
													}
												</div>
												{mark.naziv_cilja &&
													<div className="bottom-flex-container top-border">
														{mark.naziv_cilja}
													</div>
												}
											</div>
											<div className="date-container">
												<div>{this.getDan(mark.datum)}</div>
												<div>{this.getMjesec(mark.datum)}</div>
											</div>
										</div>
									</ListItem>
								)}
							</List>
						</div>
					</Grid>
					<Grid item md={4} className="chart-grid-container">
						
						<div className="chart-container">
				          	<CurrMarksChart data={this.getTekucaStatistika(this.props.marks)} is_complete={false}/>
				        </div>
			    	</Grid>
			     </Grid>
			</div>
        );
    }
}
