import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
//import {history} from '../_helpers/history';
import { store } from '../../_helpers/store';
import {Spiner} from '../../Spiner/Spiner';
import Moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import './Notification.css'

class Notification extends React.PureComponent {

	constructor(props) {
        super(props);  
    }

	componentDidMount() {		
	}

	getMjesec = (ts) => {
		let datum = new Date(ts);
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (ts) => {
		let datum = new Date(ts);
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getAvg(sum, cnt){
		if (cnt == 0)
			return '';

		return Math.round((sum / cnt) * 100) / 100;
	}

	getBg(nazivTipa){
		if (nazivTipa.startsWith("Nez"))
            return "red-bg";
        else if (nazivTipa.startsWith("Dob"))
            return "orange-bg";
        else 
            return "green-bg";
	}
	
    render() {
  		return (
  			
        	<div className="main-flex-notif-container">
				<div className="right-flex-container">
					<div className="upper-flex-container-tick">
						<div className="main-text-notif" style={{margin : 5}}>
							{this.props.item.headline}
						</div>										
					</div>
					<div className="bottom-flex-container w-100"  style={{marginTop : 5}}>
						<div className="w-100" dangerouslySetInnerHTML={{__html: this.props.item.body}} />
					</div>
				</div>
				<div className="date-container" style={{margin : 10}}>
					<div>{this.getDan(this.props.item.ts)}</div>
					<div>{this.getMjesec(this.props.item.ts)}</div>
				</div>
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const { class_id } = state.school_classes;
    return {
        loggedIn, user, class_id
    };
}

const connectedNotification  = connect(mapStateToProps)(Notification);
export {connectedNotification as Notification}; 