import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
ResponsiveContainer, LabelList} from 'recharts';
import PropTypes from 'prop-types';


class CustomizedLabel extends PureComponent {
    constructor(props) {
        super(props);  
    }
    render(){
    const {x, y, fill, value} = this.props;
    return (<text 
               x={x} 
               y={y} 
               dy={-4} 
               fontSize='16' 
               fontFamily='sans-serif'
               fill={'#000000'}
               textAnchor="middle">{value}</text>);
    }
}


const CustomizedMostPopularLabel =(props) =>{
  const { x, y, value } = props;
  return (
    <g>
     <text x={x} y={y} fill="#000" rotate="90"></text>
    </g>
  )
}

export default class CurrMarksChart extends PureComponent { 
    constructor(props) {
        super(props);  
    }
    getBarColor = (index) => {
        if (index == 0)
            return '#ef3361';

        if (index == 1)
            return '#ffa851';

        if (index == 2 || index == 3)
            return '#0c99c1';
        
        if (index == 4)
            return '#00b7a8';
    }

    getTitle(){
        if (this.props.is_complete)
            return "Ukupna prosječna ocjena: " + this.props.data.avg;
        else 
            return "Tekuća prosječna ocjena: " + this.props.data.avg;

    }
    //
    render() {
        return (
            <div style={{ width: '100%', height: 300 }}>
                <div>{this.getTitle()}</div>
                <ResponsiveContainer>
                    <BarChart
                        width={350}
                        height={200}
                        data={this.props.data.data}
                        margin={{
                          top: 20, right: 20, bottom: 20, left: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="ocjena" />
                        <YAxis/>
                        <Tooltip />
                        
                        
                        <Bar dataKey="ukupan broj" fill="#1976d2"  label={{position: 'top' }}>
                          {
                            this.props.data.data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={this.getBarColor(index)}/>
                            ))
                          }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
