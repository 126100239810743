import React, { Component } from 'react';
import './MarkIcon.css'

export class MarkIcon extends Component {
	constructor(props) {
        super(props);  
    }


    render() {
    	//const specText = this.getSpecText(this.props.id);
        const {mark} = this.props;
        if (!mark) return (<div/>); 

        let colorStyle = "default-bg ";

        if (mark == '1')
            colorStyle = "red-bg";
        else if (mark == '2')
            colorStyle = "orange-bg";
        else if (mark == '5')
            colorStyle = "green-bg";

       	return (
            <div className={"mark-icon-container " + colorStyle}>
       		   <div className="mark-icon-style">{this.props.mark}</div>
        	</div>
        ); 
    }
}