import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
//import {history} from '../_helpers/history';
import { store } from '../../_helpers/store';
import {Spiner} from '../../Spiner/Spiner';
import Moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import './Notification.css'
import { Notification } from './Notification';

class Notifications extends React.PureComponent {

	constructor(props) {
        super(props);  
    }

	componentDidMount() {		
	}

	getMjesec = (ts) => {
		let datum = new Date(ts * 1000);
		if (!datum) return '';
		let mjesec = Moment(datum).format('MMM');
		if (mjesec == 'May') return 'Maj';
		if (mjesec == 'Oct') return 'Okt';
		return mjesec;
	}

	getDan = (ts) => {
		let datum = new Date(ts * 1000);
		if (!datum) return '';
		return Moment(datum).format('DD');
	}

	getAvg(sum, cnt){
		if (cnt == 0)
			return '';

		return Math.round((sum / cnt) * 100) / 100;
	}

	getBg(nazivTipa){
		if (nazivTipa.startsWith("Nez"))
            return "red-bg";
        else if (nazivTipa.startsWith("Dob"))
            return "orange-bg";
        else 
            return "green-bg";
	}
	//<ConductIcon nazivtipa={item.nazivtipa}/>
    render() {
  		return (
  			
        	<div>        		
				{this.props.notifications.map((item, index) =>
		            <Notification key={index} item={item}/>
				)}
			</div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.authentication;
    const { class_id } = state.school_classes;
    return {
        loggedIn, user, class_id
    };
}

const connectedNotifications  = connect(mapStateToProps)(Notifications);
export {connectedNotifications as Notifications}; 