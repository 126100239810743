import React, { Component } from 'react';
import './LoginHeader.css'

export class LoginHeader extends Component {

    render() {
        return (
        <div>
           <div className="d-none d-sm-block bigBar">
				<div className="d-flex justify-content-start align-items-center w-100 h-100">					
					<i className="fa fa-graduation-cap fa-2x mx-2" aria-hidden="true"></i>				
				</div>
			</div>			
			<div className="d-sm-none smallBar">
				<div className="d-flex justify-content-center align-items-center h-100">						
					<img src="assets/img/logo_small.png"/>					
				</div>
			</div>
		</div>
        );
    }
}
