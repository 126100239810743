import axios from 'axios';

export default 
{
loadConduct : (class_id) => {
	
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/conduct', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
,
loadAttendance : (class_id) => {
	
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/attendance', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
,
loadDiscipline : (class_id) => {
	
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/discipline', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
,
loadExtTest : (class_id) => {
	
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/exttest', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
, 
loadParentCoop : (class_id) => {
	
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/parentcoop', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
,
loadMarks : (class_id) => {
	
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/marks', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}, 
loadNotifications : (class_id) => {
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/get_notif', {params: {class_id: class_id}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
, 
loadAllNotifications : () => {
	return new Promise ((resolve, reject) => 
	{
		axios.get('/api/get_all_notif', {params: {}})
		.then((response) => {                       
			resolve(response.data);
	 	})
	 	.catch(function (error) {
	     	reject(error);	
	  	});
	 });
}
};