import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import {Spiner} from '../../Spiner/Spiner';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import { withTheme } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';

//height: '100vh',
const styles = theme => {   
    return {
        root: {

        },
        close: {
            padding: theme.spacing(0.5),
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        },
    };
};

class Contact extends React.Component {
	constructor(props) {
        super(props);    

        this.state = {
            name: '',
            mail: '',
            content : '', 
            loading : false, 
            open_snack : false, 
            msg : ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        this.setState({open_snack : false});
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { name, mail, content } = this.state;        
        console.log(this.state);
        //
        if (!name){
            this.setState({open_snack : true, msg : 'Morate unijeti ime!'});
            return;
        }

        if (!mail){
            this.setState({open_snack : true, msg : 'Morate unijeti mail adresu!'});
            return;
        }

        if (!content){
            this.setState({open_snack : true, msg : 'Morate unijeti sadržaj!'});
            return;
        }
        this.setState({ loading: true });
        let mailobj  = {'name' : name, 'mail' : mail, 'content' : content, class_id: this.props.class_id};

        axios.post('/api/sendmail', mailobj)
        .then(response => {
            this.setState({loading: false});
            if (response && response.data){
                this.setState({open_snack : true, msg : 'Došlo je do greške prilikom slanja mail-a!'});
            }
            else{
                this.setState({name: '', mail: '', content : ''});
                this.setState({open_snack : true, msg : 'Mail je uspješno poslat!'});
            }
        }
        ,error => {
            this.setState({ loading: false});
            this.setState({open_snack : true, msg : 'Došlo je do greške prilikom slanja mail-a!'});
        });
    }

	componentDidMount() {
	}
    // xs={12} sm={8} md={5}
    render() {
    	const { user } = this.props;
    	const { name, mail, content, loading } = this.state;
        const { container, classes } = this.props;

        return (
            <div>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
				{loading &&
					<Spiner></Spiner>
				}               
                <Grid item xs={12} component={Paper} elevation={6} square>
                    <div className={classes.paper}>                
                        <Avatar className={classes.avatar}>
                            <MailOutlinedIcon />
                        </Avatar>
                    </div>
                    <Typography component="h1" variant="h5">
                        Pošaljite mail odjeljenskom starješini
                    </Typography>
                    <div style={{margin : '20px'}}>
                        <form className={classes.form} onSubmit={this.handleSubmit}>                        
                            <TextField variant="outlined" margin="normal" fullWidth id="name" label="Vaše ime" name="name" autoComplete="name" autoFocus
                                onChange={this.handleChange} value={this.state.name}
                            />
                            <TextField variant="outlined" margin="normal" fullWidth id="mail" label="Vaša mail adresa" name="mail" autoComplete="mail" autoFocus
                                onChange={this.handleChange} value={this.state.mail}
                            />
                            <TextField variant="outlined" multiline rows="5" margin="normal" fullWidth name="content" label="Sadržaj" id="content" onChange={this.handleChange}
                                value={this.state.content}
                            />

                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            Pošalji
                            </Button>

                        </form>
                    </div>
                </Grid>
				
			</Grid>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
            open={this.state.open_snack} autoHideDuration={4000} onClose={this.handleClose} ContentProps={{'aria-describedby': 'message-id',}}
                message={<span id="message-id">{this.state.msg}</span>}
                action={[<IconButton key="close" aria-label="close" color="inherit" className={classes.close} onClick={this.handleClose}>
                            <CloseIcon />
                          </IconButton>,]}
              />
            </div>
        );
    }
}

const connectedContact = withStyles(styles, { withTheme: true})(Contact);
export {connectedContact as Contact}; 