import React, { Component } from 'react';
import Moment from 'moment';

export default class DisciplineIcon extends Component {
	constructor(props) {
        super(props);  
    }

    getFirst(id) {
        if (!id){
            return "";
        }
        return id.substring(0, 1).toUpperCase();
    }

    getJeIskljucenje(id) {
        if (!id) return false;

        if (id.includes(" iz ")) 
            return true;
        else 
            return false;
    }
    getStyle(id){
        if (this.getJeIskljucenje(id))
            return {backgroundColor: '#cc0000'};
        else 
            return {};
    }
    render() {            
       	return (
       		<div className="icon-container" style={this.getStyle(this.props.naziv_tipa)}>
       		    <div className="icon-style">{this.getFirst(this.props.naziv_tipa)}</div> 
        	</div>

        ); 
    }
}