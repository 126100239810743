import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
ResponsiveContainer, LabelList} from 'recharts';
import PropTypes from 'prop-types';

export default class AttSumChart extends PureComponent { 
    constructor(props) {
        super(props);  
    }
    getBarColor = (index) => {
        /*if (index == 0)
            return '#ff0000';*/

        if (index == 0)
            return '#ffa851';

        /*if (index == 2 || index == 3)
            return '#8b9dc3';*/
        
        if (index == 1)
            return '#00b7a8';
    }

    getTitle(){
        return "Ukupan broj izostanaka";
    }

    //
    render() {
        return (
            <div style={{ width: '100%', height: 300 }}>
                <div>{this.getTitle()}</div>
                <ResponsiveContainer>
                    <BarChart
                        width={350}
                        height={200}
                        data={this.props.data}
                        margin={{
                          top: 20, right: 20, bottom: 20, left: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip />
                        
                        
                        <Bar dataKey="value" fill="#1976d2"  label={{position: 'top' }}>
                          {
                            this.props.data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={this.getBarColor(index)}/>
                            ))
                          }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
