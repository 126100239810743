import React, { Component } from 'react';
import Moment from 'moment';

export default class ConductIcon extends Component {
	constructor(props) {
        super(props);  
    }

    getFirst(id) {
        if (!id){
            return "";
        }
        if (id.includes("etvrto")) return '4';
        else if (id.includes("na tre")) return '3';
        else if (id.includes("na drugo")) return '2';
        else if (id.includes("na prvo")) return '1';
        
        return "";
    }

    render() {            
       	return (
       		<div className="icon-container">
       		    <div className="icon-style">{this.getFirst(this.props.nazivtipa)}</div> 
        	</div>

        ); 
    }
}